/* Define css class for global loading message to cover 
   screen during axios operations */
#loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #CCCCCC;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    font-size: 20px;
    opacity:0.5;
}
